









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class TextIndicator extends Vue {
  @Prop() public value!: number;
  @Prop({default: () => [
    'QAI_GOOD', 'QAI_WARNING', 'QAI_CRITICAL',
  ]}) public options!: any;
}
